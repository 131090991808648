/* eslint-disable react/display-name */
import { DefaultTagline, LOGIN_PAGE_CONFIG } from "@libs/products";
import { Grid } from "@mui/material";
import { Text } from "@redsift/design-system";
import { useContext } from "react";
import styled from "styled-components";
import DotsGradient from "../../public/dots-gradient.svg";
import { AppContext } from "../contexts/AppContext";

interface LoginMarketingCardProps {
  isSignUp: boolean;
}

const StyledDotsGradient = styled(DotsGradient)`
  margin-top: 30px;

  @media only screen and (max-width: 1440px) {
    height: 216px;
  }
`;

const LoginMarketingCard = ({ isSignUp }: LoginMarketingCardProps) => {
  const ctx = useContext(AppContext);
  const product = ctx.getProduct();
  const {
    Logo,
    TagLine = DefaultTagline,
    name = "Red Sift Pulse Platform",
  } = LOGIN_PAGE_CONFIG[product]
    ? LOGIN_PAGE_CONFIG[product]
    : LOGIN_PAGE_CONFIG["pulse-platform"];

  return (
    <>
      <Grid container>
          <Grid
            item
            xs={7}
          >
            <StyledDotsGradient
            ></StyledDotsGradient>
          </Grid>
          <Grid item xs={5}></Grid>
        <Grid item xs={1}></Grid>

        <Grid
          item
          xs={11}
          style={{
            marginTop: "35px",
          }}
        >
          <Logo />
          <Text
            as="p"
            style={{
              marginTop: "12px",
              color: "white",
              fontSize: "65px",
              lineHeight: "60px",
            }}
          >
            <strong>{name}</strong>
          </Text>
          <TagLine />
        </Grid>
      </Grid>
    </>
  );
};

export default LoginMarketingCard;
