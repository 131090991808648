/* eslint-disable react/display-name */
import { AppContext } from "@components/contexts/AppContext";
import colorlog from "@libs/colorlog";
import { useRouter } from "next/router";
import { useContext, useEffect, useState } from "react";
import { PRODUCT_NAMES } from "./products";

const log = colorlog("libs");

// withAuthComponent.tsx
const withSanitisedQuery = (Component: any) => (props: any) => {
  const router = useRouter();
  const ctx = useContext(AppContext);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    (async () => {
      const product = router.query.product;
      if (router.isReady) {
        if (product) {
          if (typeof product !== "string") {
            log.error("product is not a string:", product);
            await router.replace("/404");
          } else if (!PRODUCT_NAMES[product]) {
            log.error("product is not supported setting pulse-platform as default:", product);
            ctx.setProduct("pulse-platform");
          } else {
            ctx.setProduct((router.query.product as string) || "pulse-platform");
          }
        }
        setReady(true);
      }
    })();
  }, [ctx, router]);

  if (!ready) {
    return null;
  }

  return <Component {...props} />;
};

export default withSanitisedQuery;
